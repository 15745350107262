import React from "react"
import {graphql} from "gatsby"
import PageHeader from "../components/PageHeader/PageHeader";

export const query = graphql`
  query($slug: String!) {
    contentfulPages(slug: { eq: $slug }) {
      title
    }
  }
`

const Stories = (props: any) => {
  return <PageHeader header={props.data.contentfulPages.title} />
}

export default Stories
